import React from "react"
import {graphql, Link} from 'gatsby';
import Layout from "../components/layout"
import Seo from '../components/seo';
import HeroBreadcrumb from '../components/HeroBreadcrumb';
export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const location = '/blog/x';
    console.log(this.props);
    console.log({posts});

    // const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title
    // const posts2 = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/blog' : '/blog/' + (currentPage - 1).toString()
    const nextPage = isLast ? '/blog/' + currentPage :  '/blog/' + (currentPage + 1).toString()
    // console.log(this.props.pageContext);



    return (
        <Layout location={location} title={"Page " + currentPage + " of my fitness blog posts"}>
          <Seo title="All fitness blog posts" />
          <HeroBreadcrumb />
          <h1 style={{textAlign: "center", margin: "2em"}}>Page {currentPage} of blog</h1>

          <div style={{maxWidth:"700px",margin:"40px auto"}}>
            <ol style={{ listStyle: `none` }}>
              {posts.map(({ node }) => {
                // const title = post.frontmatter.title || post.fields.slug
                const title = node.frontmatter.title || node.fields.slug
                const post = node;
                const slug = post.fields.slug
                // console.log({node,slug,title})
                // return <div key={node.fields.slug}>{title}</div>

                return (
                    <li key={slug}>
                      <article
                          // className="post-list-item"
                          // itemScope
                          // itemType="http://schema.org/Article"
                      >
                        {/*<header>*/}
                          <h4>
                        {/*    hi*/}
                            <Link to={slug+ '/'} >{title}</Link>
                          </h4>
                          {/*<small>{"asdf" ?? post.frontmatter.date}</small>*/}
                        {/*</header>*/}
                        {/*<section>*/}
                          <p
                              dangerouslySetInnerHTML={{
                                __html: post.frontmatter.description || post.excerpt,
                              }}
                              itemProp="description"
                          />
                        {/*</section>*/}
                      </article>
                    </li>

                )
              })}
            </ol>

            <p><Link to={prevPage+'/'}>Prev</Link> &bull; <Link to={nextPage+'/'}>Next page</Link></p>
          </div>
        </Layout>

    )
  }
}
export const blogListQuery = graphql`
    query blogListQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        description
                    }
                }
            }
        }
    }
`
